<template>
	<div class="settle">
		<div class="center">ip需求</div>
		<div class="content">
			<div class="form">
				<div class="title">
					<div class="spikel"></div>
					申请授权
				</div>
				<div class="formBox">
					<el-form ref="form" :model="form" label-width="100px" :rules="rules">
						<el-form-item label="联系人姓名：" prop="name">
							<el-input v-model="form.name" placeholder="请输入联系人姓名..."></el-input>
						</el-form-item>
						<el-form-item label="联系人电话：" prop="tel">
							<el-input v-model="form.tel" placeholder="请输入联系人电话..."></el-input>
						</el-form-item>
						<el-form-item label="入驻ip基本信息：" prop="content">
							<el-input placeholder="请输入入驻ip基本信息..." v-model="form.content" resize="none" :rows="7" type="textarea"></el-input>
						</el-form-item>
						<el-form-item label="选择器：">
							<el-select v-model="value" placeholder="请选择">
								<el-option label="选我啊" :value="1"> </el-option>
							</el-select>
						</el-form-item>
					</el-form>
				</div>
				<div class="form-bottoms">
					<div class="form-bottom"></div>
					<div class="bottom-button">
						<el-button type="primary" @click="onSubmit">提交申请</el-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapActions,
		mapGetters
	} from "vuex";
	export default {
		data() {
			var checkPhone = (rule, value, callback) => {
				if (!value) {
					return callback(new Error("手机号不能为空"));
				} else {
					const reg = /^1[1|3|4|5|7|8|9][0-9]\d{8}$/;
					//console.log(reg.test(value));
					if (reg.test(value)) {
						callback();
					} else {
						return callback(new Error("请输入正确的手机号"));
					}
				}
			};

			return {
				value: "",
				form: {
					name: "",
					tel: "",
					title: "",
					power: "",
					email: "",
					type: "",
					content: "",
				},
				rules: {
					name: [{
						required: true,
						message: "请输入素材/IP名称",
						trigger: "blur"
					}, ],
					tel: [{
							required: true,
							message: "请输入权力拥有方",
							trigger: "blur"
						},
						{
							validator: checkPhone
						},
					],
					title: [{
						required: true,
						message: "请输入联系人姓名",
						trigger: "blur"
					}, ],
					power: [{
						required: true,
						message: "请输入联系人电话",
						trigger: "blur"
					}, ],
					email: [{
						required: true,
						message: "请输入电子邮箱",
						trigger: "blur"
					}],
					type: [{
						required: true,
						message: "请输入入库类型",
						trigger: "blur"
					}],
					content: [{
						required: true,
						message: "请输入简介说明",
						trigger: "blur"
					}, ],
				},
				uinfo: {
					token: '',
					nickname: '',
					phone: '',
					userinfoL: [],
				},
			};
		},
		computed: {
			...mapGetters(["userinfo"]),
		},
		methods: {
			...mapActions(["Setuserinfo"]),
			onSubmit() {
				this.$refs.form.validate((valid) => {
					if (valid) {
						//console.log("正则验证成功");
					} else {
						//console.log("正则校验失败");
						return false;
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.settle {
		height: 100%;
	}

	.center {
		height: 250px;
		background: url("../../assets/service/service.png");

		background-size: 100% 100%;
		color: #ffffff;
		font-weight: 900;
		font-size: 40px;
	}

	.content {
		background: rgb(248, 248, 248);
		height: 100%;

		.form {
			transform: translateY(-20px);
			width: 1200px;
			background: #ffffff;
			margin: 0 auto;
		}
	}

	.title {
		display: flex;
		align-items: center;
		padding: 10px 30px;
		border-bottom: 1px solid #dfdfdf;

		.spikel {
			width: 4px;
			height: 20px;
			margin-right: 14px;
			background: rgb(0, 118, 254);
		}
	}

	.formBox {
		padding: 77px 0px 86px 86px;
		width: 600px;
	}

	.form-bottom {
		//   margin-top: 86px;
		border-bottom: 1px solid #dfdfdf;
	}

	.bottom-button::v-deep {
		margin-top: 60px;

		.el-button {
			font-size: 10px;
			padding: 13px 60px;
			border-radius: 0px;
			background: rgb(0, 118, 254);
		}
	}

	.form-bottoms {
		padding: 0px 0px 100px 83px;
	}
</style>
